import copy from 'copy-to-clipboard';

export const initCopyUrl = () => {

    const copyElements = document.querySelectorAll('.copy-url');

    copyElements.forEach(element => {
        const url = element.dataset.href;
        element.addEventListener('click', () => {
            copy(url);
        })
    });

}

export const initCalendarTrigger = () => {
    const triggerElements = document.querySelectorAll('.trigger');

    triggerElements.forEach(element => {
        const id = element.dataset.id;

        element.addEventListener('click', () => {
            const parent = document.querySelector(`#${id}`);

            if (parent.classList.contains('active')) {
                parent.classList.remove('active');
            } else {
                parent.classList.add('active');
            }
        })
    });
}

export const initEventFilter = () => {
    const filterSelect = document.getElementById('topicfilter')

    if (!filterSelect) {
        return
    }

    const ajaxCall = url => {
        url = url.replace(/events(\.json)*/,'events.json');
        fetch(url).then(response => response.text()).then(data => {
            const node = document.querySelectorAll('.event-list')[0];
            node.innerHTML = data;
        });
    }

    const removeActive = () => {
        const ah1 = document.querySelectorAll('.event-filter li a');
        const ah2 = document.querySelectorAll('.event-filter li .select');
        const ah = [...ah1,...ah2];
        ah.forEach(w => {
            w.classList.remove('active');
        });
    }

    const allHrefs = document.querySelectorAll('.event-filter li a');
    allHrefs.forEach(v => {
        v.onclick = e => {
            removeActive();
            e.target.classList.add('active');
            ajaxCall(e.target.getAttribute('href'));
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    });

    filterSelect.addEventListener('focusin', () => {
        const filterWrapper = filterSelect.parentNode;
        filterWrapper.classList.add('focused')
    })

    filterSelect.addEventListener('focusout', () => {
        const filterWrapper = filterSelect.parentNode;
        filterWrapper.classList.remove('focused')
    })

    filterSelect.addEventListener('change', (e) => {
        // window.open(filterSelect.value, '_self');
        removeActive();
        e.target.parentNode.classList.add('active');
        ajaxCall(filterSelect.value);
        e.preventDefault();
        e.stopPropagation();
        return false;
    })
}