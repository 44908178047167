import Swiper from 'swiper/swiper-bundle.min.js';

export const InitBlockSliderBig = () => {
    var swiper = new Swiper('.slider-big .swiper', {
        // slidesPerView: 'auto',
        effect: "fade",
        spaceBetween: 0,
        centeredSlides: true,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // Enable debugger
        // debugger: true,
    });
}
