export const InitAnimations = () => {
    let lastPosition = 0;
    const header = document.querySelectorAll('header.page');
    if (header.length==0) return;

    document.addEventListener('scroll', (e) => {
        let positionY = Math.floor(window.scrollY/(document.body.scrollHeight-document.documentElement.clientHeight)*1000);
        if (positionY!=lastPosition) {
            lastPosition = positionY;
            // console.log(positionY,header[0].className);
            if (positionY>25) {
                if (!header[0].classList.contains('small')) header[0].classList.add('small');
            } else {
                if (header[0].classList.contains('small')) header[0].classList.remove('small');
            }
        }
    });
}
