import Swiper from 'swiper/swiper-bundle.min.js';

export const InitBlockSliderLogo = () => {
    var swiper = new Swiper('.slider-logo .swiper', {
        slidesPerView: window.innerWidth<500?2:4,
        spaceBetween: 30,
        // centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
    });
}
