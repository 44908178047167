export const InitParallaxEffect = () => {
    let lastPosition = 0;
    let scrollElements = [
        ...document.getElementsByClassName('block-cta'),
        ...document.querySelectorAll('.block-spacer.theme-blue'),
        ...document.querySelectorAll('.block-book-search::before')
    ]
    if (scrollElements.length==0) {
        return;
    }

    document.addEventListener('scroll', (e) => {
        let positionY = Math.floor(window.scrollY/(document.body.scrollHeight-document.documentElement.clientHeight)*1000);
        if (positionY!=lastPosition) {
            lastPosition = positionY;
            scrollElements.forEach(e => {
                e.style.backgroundPosition = '0 -'+positionY+'px';
            });
        }
    });
}
