export const initBlogFilter = () => {
    const blogFilter = document.querySelector('.blog-filter')
    if (!blogFilter) {
        return
    }

    const filterSelect = document.getElementById('tagfilter')
    if (!filterSelect) {
        return
    }

    const assignPagination = () => {
        const allLinks = document.querySelectorAll('.pagination li a');
        allLinks.forEach(v => {
            v.onclick = e => {
                ajaxCall(e.target.parentNode.getAttribute('href'));
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        });
    }
    assignPagination();

    const ajaxCall = url => {
        url = url.replace(/neuigkeiten(\.json)*/,'neuigkeiten.json');
        fetch(url).then(response => response.text()).then(data => {
            const node = document.querySelectorAll('.blog-filter+.container')[0];
            node.innerHTML = data;
            assignPagination();
            const filterId = document.getElementById('blog-filter');
            if (filterId) filterId.scrollIntoView();
        });
    }


    const removeActive = () => {
        const ah1 = document.querySelectorAll('.blog-filter li a');
        const ah2 = document.querySelectorAll('.blog-filter li .select');
        const ah = [...ah1,...ah2];
        ah.forEach(w => {
            w.classList.remove('active');
        });
    }

    const allHrefs = document.querySelectorAll('.blog-filter li a');
    allHrefs.forEach(v => {
        v.onclick = e => {
            removeActive();
            e.target.classList.add('active');
            ajaxCall(e.target.getAttribute('href'));
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    });

    // dropdown
    filterSelect.addEventListener('focusin', () => {
        const filterWrapper = filterSelect.parentNode;
        filterWrapper.classList.add('focused')
    })

    filterSelect.addEventListener('focusout', () => {
        const filterWrapper = filterSelect.parentNode;
        filterWrapper.classList.remove('focused')
    })

    filterSelect.addEventListener('change', (e) => {
        // window.open(filterSelect.value, '_self');
        removeActive();
        e.target.parentNode.classList.add('active');
        ajaxCall(filterSelect.value);
        e.preventDefault();
        e.stopPropagation();
        return false;
    })
}
