export const initBlockAccordion = () => {
    const blockAccordion = document.querySelectorAll('.block-accordion .header');

    blockAccordion.forEach(accordion => {

        accordion.addEventListener('click', (event) => {
            let element = event.target.closest('li');
            element.classList.toggle('open');
        });

    });

}