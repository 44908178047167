export const vhFix = () => {
    // in CSS use calc(var(--vh, 100vh))
    const vheight = document.getElementById('vheight');
    const fixNeeded = vheight.clientHeight!=document.documentElement.clientHeight;
    vheight.remove();
    // console.log('vhFix',fixNeeded);
    if (fixNeeded) {
        document.documentElement.style.setProperty('--vh', window.innerHeight+'px');
    }
}
