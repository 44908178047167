export const initNavigation = () => {
    const closeBtn = document.querySelectorAll('.page nav .close_btn');

    closeBtn[0].addEventListener('click', (event) => {
        let element = document.querySelectorAll('nav.menu');
        element[0].classList.toggle('show');

        let header = document.querySelectorAll('header.page');
        header[0].classList.toggle('show');

        document.body.classList.toggle('noscroll');
    });

    const menuBtn = document.querySelectorAll('.menu_btn');

    menuBtn[0].addEventListener('click', (event) => {
        let element = document.querySelectorAll('nav.menu');
        element[0].classList.toggle('show');

        let header = document.querySelectorAll('header.page');
        header[0].classList.toggle('show');

        document.body.classList.toggle('noscroll');
    });

    const menuAccordions = document.querySelectorAll('nav.menu span');

    menuAccordions.forEach(accordion => {

        accordion.addEventListener('click', (event) => {
            let element = event.target.closest('li');
            element.classList.toggle('open');
        });

    });

}