export const initHeader = () => {
    const languageSelect = document.getElementById('languageselect');
    if (!languageSelect) {
        return
    }

    languageSelect.addEventListener('change', () => {
        const language = languageSelect.value;
        if (language.length == 0) return;

        const googleHostname = location.hostname.replace('.translate.goog', '').replaceAll('.', '-');
        const url = location.protocol + '//' + googleHostname + '.translate.goog' +
            location.pathname + '?_x_tr_sl=auto&_x_tr_tl=' + language + '&_x_tr_hl=' + language;
        location.href = url;
    });
}