import Swiper from 'swiper/swiper-bundle.min.js';

export const InitBlockSliderSmall = () => {
    function eotoPlugin({ swiper, extendParams, on }) {
        extendParams({
            debugger: false,
            zoomClass: false,
        });

        on('slideChange', () => {
            if (swiper.params.debugger) {
                console.log('slideChange', swiper.previousIndex, '->', swiper.activeIndex);
            }

            if (swiper.params.zoomClass) {
                const images = document.querySelectorAll('.slider-small .swiper .swiper-image');
                images[swiper.previousIndex].classList.remove('zoom');
                images[swiper.activeIndex].classList.add('zoom');
            }
        });
    }

    Swiper.use([eotoPlugin]);
    var swiper = new Swiper('.slider-small .swiper', {
        initialSlide: 1,
        slidesPerView: 'auto',
        spaceBetween: 0,
        centeredSlides: true,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // Enable debugger
        // debugger: true,
        zoomClass: true
    });
}
