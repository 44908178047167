import Swiper from 'swiper/swiper-bundle.min.js';

export const InitEventSlider = () => {
    var swiper = new Swiper('.event-slider .swiper', {
        initialSlide: 1,
        slidesPerView: window.innerWidth<500?1:3,
        spaceBetween: 40,
        centeredSlides: true,
        // Navigation arrows
        navigation: {
            nextEl: '.event-swiper-button-next',
            prevEl: '.event-swiper-button-prev',
        },

        // Enable debugger
        // debugger: true,
    });
}
