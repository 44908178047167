export const zoomText = () => {
    const zoomTextTrigger = document.querySelector('.access-menu [data-trigger="zoomText"]')
    let zoomLevel = window.localStorage.getItem('zoomLevel') || 0

    zoomTextTrigger.addEventListener('click', (event) => {

        event.preventDefault()
        zoomLevel++

        if (zoomLevel > 2) {
            zoomLevel = 0
        }

        window.localStorage.setItem('zoomLevel', zoomLevel)
        document.documentElement.style.setProperty('--font-size-base', (16 + zoomLevel * 2) + 'px');
    })
}

export const toggleContrast = () => {
    const contrastTrigger = document.querySelector('.access-menu [data-trigger="toggleContrast"]')

    contrastTrigger.addEventListener('click', (event) => {
        event.preventDefault()
        const body = document.querySelector('body')

        if (body.classList.contains('contrastMode')) {
            body.classList.remove('contrastMode')
            window.localStorage.setItem('contrastMode', false)
        } else {
            body.classList.add('contrastMode')
            window.localStorage.setItem('contrastMode', true)
        }
    })
}

export const restoreSettings = () => {
    const zoomLevel = window.localStorage.getItem('zoomLevel') || 0
    const contrastMode = window.localStorage.getItem('contrastMode') || false

    document.documentElement.style.setProperty('--font-size-base', (16 + zoomLevel * 2) + 'px');

    if (contrastMode == 'true') {
        const body = document.querySelector('body')
        body.classList.add('contrastMode')
    }

}