export const initBlockTextExpander = () => {
    const textExpanders = document.querySelectorAll('.text-expander-trigger');

    textExpanders.forEach(expander => {

        expander.addEventListener('click', (event) => {
            const triggerId = expander.dataset.id;
            const fullText = document.querySelector(`#exp_${triggerId}`);
            console.log(fullText);
            // TODO toggle
            fullText.classList.add('expanded')
            expander.classList.add('expanded')
        })

    });

}